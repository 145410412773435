<template>
    <div class="guide-detail btm-menu show-bulletpoints-normal">
        <Breadcrumb :background="true" :content="breadcrumb" :backFocus="true"/>

        <div class="section bg-jade">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12 d-flex align-items-end header-row">
                        <h3 class="main-title">{{title}}</h3>
                        <share-button/>
                    </div>
                </div>
            </div>
        </div>

        <div class="section">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12 col-lg-8">
                        <div class="para">
                            <p v-html="body"></p>
                            <einzelheiten v-if="einzelheiten!=null" v-for="einzelheit in einzelheiten" :key="einzelheit.id" :content="einzelheit"/>
                        </div>
                    </div>

                    <div class="col-lg-3 ml-auto d-none d-lg-block" v-if="hilfreicheLinks!=null">
                        <HelpfulLinksBlock :hilfreicheLinksArray="hilfreicheLinks"/>
                    </div>
                </div>
            </div>
        </div>

        <div class="section pt-lg-0">
            <div class="container-fluid">
                <div class="row" v-if="similar!==null">
                    <div class="col-12">
                        <h5 class="text-uppercase text-dark mb-md-20 section-title">Ähnliche Themen</h5>
                    </div>
                </div>
                <div class="row mobile--scrolling" v-if="similar!==null">
                    <ratgeber-card v-for="(item, index) in similar" :category="category" :key="index" :content="item"/>
                </div>

                <div class="row d-lg-none mt20" v-if="hilfreicheLinks!=null">
                    <div class="col-12">
                        <HelpfulLinksBlock :hilfreicheLinksArray="hilfreicheLinks"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import Resource from '@/api/resource';
  import { getContentsOfType } from '@/api/content';
  import { getFieldValues, showLoader, hideLoader } from '@/utils/helpers';
  const contentResource = new Resource('contents');

  export default {
    name: 'RatgeberArticleDetail',
    components: {
        Einzelheiten: () => import('@/components/controls/Einzelheiten.vue'),
        ShareButton: () => import('@/components/controls/ShareButton.vue'),
        Breadcrumb: () => import('@/components/layout/Breadcrumb.vue'),
        RatgeberCard: () => import('@/components/cards/Ratgeber.vue'),
        HelpfulLinksBlock: () => import('./components/HelpfulLinksBlock.vue'),
    },
    data() {
        return {
            content: null,
            dataSet: {
              total: 0,
              data: null,
              meta:null,
              query: {
                page:1,
                limit: 5,
                selectedAppId: this.$appId,
                keyword: this.kategorie,
                selectRandomly: true,
                selectedContentTypeId: this.$ratgeberId,
                view_status: 1,
                sort: {
                  prop: '',
                  order: 'asc'
              },
          },
      },
  }
},
watch: {
  id(newId, oldId) {
    if (newId!=oldId) {
      this.getContent();
      this.isActiveLocation=true;
      this.$scrollTo();
  }
},
kategorie(newVal, oldVal) {
    /*parse that thing, so that we get only those contents that fit to the category*/
    this.dataSet.query.keyword='"'+this.category+'"';
    this.getSimilarContents();
}
},
created() {
    this.getContent();
    this.isActiveLocation=true;
},
computed: {
    id() {
        return this.$route.params.id;
    },
    slug() {
        return this.$route.params.guide;
    },
    category(){
        if(this.slug=="Kinder&Jugendliche"){
            return "Kinder & Jugendliche";
        }
        if(this.slug=="NeuinWolfsburg"){
            return "Neu in Wolfsburg";
        }
        return this.slug;
    },
    title(){
        var value = getFieldValues(this.content,'title');
        return value!==null?value:null;
    },
    body(){
        var value = getFieldValues(this.content,'body');
        return value!==null?value:null;
    },
    kategorie(){
        var value = getFieldValues(this.content,'kategorie');
        if(value!=null){
            if(Array.isArray(value) && value.length>0){
                return value[0];
            }
            return value;
        }
        return null;
    }, 
    breadcrumb(){
        return 'Ratgeber | '+this.category+' | '+this.title;
    },
    einzelheiten(){
        var value = getFieldValues(this.content,'einzelheiten');
        if(value!=null){
            if(!Array.isArray(value)){
                return [value];
            }
            return value;
        }
        return null;
    },
    hilfreicheLinks(){
        var value = getFieldValues(this.content,'hilfreiche_links');
        if(value!=null){
            if(!Array.isArray(value)){
                return [value];
            }
            return value;
        }
        return null;
    },
    similar(){
        if(this.dataSet.data != null && this.dataSet.data.length>0){
            var data = [];
            for(var i = 0; i < this.dataSet.data.length; i++){
                var bla = this.dataSet.data[i];
                /*check if the given content is this content - we don't want the same content as a recommendation*/
                if(bla.id!==this.content.id){
                    data.push(bla);
                }
                /*we only need four contents*/
                if(data.length == 4){
                    return data;
                }
            }
            /*hard-coded Langcode*/
            return data.length>0?data:null;
        }
        return null;
    },
},
methods:{
   getFieldValues, 
   showLoader, 
   hideLoader, 
   getContent() {
      this.loader = this.showLoader(this.loader);
      contentResource.get(this.id)
      .then(response => {
        this.content = response.data;
    })
      .finally(() => {
        this.loader = this.hideLoader(this.loader);
    });
  },
  async getSimilarContents() {
    const { limit, page } = this.dataSet.query;
    const { data, meta } = await getContentsOfType(this.$ratgeberId,this.dataSet.query);
    this.dataSet.data = data;
    this.dataSet.data.forEach((element, index) => {
      element['index'] = (page - 1) * limit + index + 1;
  });
    this.dataSet.meta = meta;
    this.dataSet.total = meta.total;
},
}
}
</script>
<style lang="scss">
    @import '../../scss/_variables.scss';

    .guide-detail{

        .header-row{

            @media (max-width: 991px) {
                font-size: 26px;
            }

            @media (max-width: 750px) {
                font-size: 20px;
            }

            .btn-share .material-icons-outlined{
                font-size: 20px;
            }
        }

        .align-items-end{
            @media (max-width: 991px) {
             align-items: baseline !important;
         }
     }
 }

</style>